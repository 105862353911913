import React from 'react'
import Head from 'components/Head'
import SiteLayout from 'components/SiteLayout'

class ErrorPage extends React.Component {
  static async getInitialProps({ res, err }) {
    if (res) res.statusCode = (err && err.status) || 404
    return {}
  }

  render() {
    return (
      <SiteLayout background='#FFFFFF'>
        <Head title='Error | One Bite App' noIndexNoFollow />
        <div className='errorContainer'>
          <h1>404</h1>
          <p>This page doesn't exist</p>
        </div>
        <style global jsx>
          {`
            .layout__main {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          `}
        </style>
        <style jsx>
          {`
            .errorContainer {
              flex-direction: column;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .errorContainer h1 {
              font-size: 72px;
            }
            .errorContainer p {
              font-size: 18px;
              text-transform: uppercase;
              font-weight: bold;
            }
          `}
        </style>
      </SiteLayout>
    )
  }
}

export default ErrorPage
